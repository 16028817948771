import React from "react";

// Customizable Area Start
import { Box, Typography, Grid, Button } from "@material-ui/core";

const webStyles = {
  container: {
    background: '#F8F9FA',
    height: '100vh',
    overflow: 'auto',
  },
  mainContainer: {
    padding: '16px 0 0'
  },
  centerAlign: {
    alignItems: 'center'
  },
  rightAlign: {
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
};
import SideNav from "../../../components/src/SideNav";
import TopNavBar from "../../../components/src/TopNavBar";
import { emptySvg } from "../../leadmanagement/src/assets";
import { checkMark } from "./assets";
// Customizable Area End

import EducationalUserProfileController, {
  Props,
} from "./EducationalUserProfileController";

export default class EducationalUserProfile extends EducationalUserProfileController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      <Box style={webStyles.container}>
        <Grid container>
          <Grid item xs={3} md={2} className="sidebar-wrapper">
            <SideNav {...this.props.navigation} />
          </Grid>
          <Grid item xs={9} md={10} className="main-content-wrapper">
            <Grid>
              <TopNavBar currentPage={{ name: 'Training Courses', path: 'training' }} />
              <Box style={webStyles.mainContainer}>
                <Box className="dashboard-nav-container">
                  <Box className="dashboard-heading">
                    <Box>
                      <Typography className="asset-heading-main">
                        Training Courses
                      </Typography>
                      <Typography className="asset-heading-sub">
                        Learn all about Builder.ai and its products and get certified.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="empty-data-block" style={{ marginTop: '80px' }}>
                  <Box className="empty-content">
                    <Box className="empty-img">
                      <img src={emptySvg} />
                    </Box>
                    <Box className="content">
                      <Typography className="title-sm">Coming Soon</Typography>
                      <Typography className="empty-sub-text">We are actively working on bringing Training courses for you. <br />Check this space later.</Typography>
                      <Box className="empty-btn">
                        <Button onClick={() => this.handleNotify()} data-test-id={"btnEmptyNavigateDeal"} className="custom-btn light-btn btn-sm download-btn">
                          {this.state.isNotify ?
                            <Typography className="asset-download-text" style={{ display: 'flex', alignItems: 'center' }}>You will be notified <img style={{ marginLeft: '6px' }} src={checkMark} /></Typography>
                            :
                            <Typography className="asset-download-text">Notify Me</Typography>
                          }
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid >
      </Box >
    );
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}

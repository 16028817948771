import React, { useEffect, useRef, useState } from "react";
import { View, StyleSheet } from "react-native";
import { withRouter } from "react-router-dom";
import './sidenav.css';
import { Accordion, AccordionDetails, AccordionSummary, Popover, Menu, MenuItem, Dialog, DialogContent, DialogActions, IconButton, Modal, Box, Typography, TextField, Button, Grid } from "@material-ui/core";
import { getToken, getWrapperClassName } from "./commonUsage";
import Loader from "../src/Loader.web";
import { useRunEngine } from "../../blocks/utilities/src/hooks/useRunEngine";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { Message } from "../../framework/src/Message";
import { useBlockHelpers } from "../../blocks/utilities/src/hooks/useBlockHelpers";
const demoVideo = require('./icons/demoVideo.mp4')
import { ErrorMessage, Field, Formik, FormikProps } from "formik";
import Select, { components } from "react-select";
import { getStorageData } from "../../framework/src/Utilities";
import * as Yup from 'yup';
import { HelpAndSupport } from "./HelpAndSupport";

interface CircularProgressProps {
    size: number; // Defines the size as a number
    progress: number; // Defines the progress as a number
    progressCompleted: number;
    progressUpto: number;
}

const siteLogo = require("./site_logo.svg")
const dashboard = require("./dashboard.svg")
const deals = require("./deals.svg")
const assets = require("./assets.svg")
const commision = require("./commision.svg")
const companies = require("./companies.svg")
const lead_companies = require("./lead_companies.svg")
const dashboard_active = require("./dashboard_active.svg")
const deals_active = require("./deals_active.svg")
const assets_active = require("./assets_active.svg")
const commision_active = require("./commision_active.svg")
const companies_active = require("./companies_active.svg")
const lead_companies_active = require("./lead_companies_active.svg")
const down_arrow = require("./down_arrow.svg")
const down_arrow_active = require("./down_arrow_active.svg")
const help_support = require("./help-support.svg")
const training = require("./training.svg")
const training_active = require("./training_active.svg")
const white_arrow = require("./white-arrow.svg")
const members_icon = require("./members.svg")
const profile_icon = require("./profile.svg")
const lead_icon = require("./lead_icon.svg")
const bank_icon = require("./bank.svg")
const checked_circle = require("./checked_circle.svg")
const closeIcon = require("./close.svg")
const boldCloseIcon = require('./icons/close_icon.png')
const whiteCloseIcon = require('./icons/white_close.png')
const videoIcon = require('./video-icon.svg')
const rightArrow = require('./chevron_right.svg')
const checkIcon = require('./check_mark.svg');
const planIllustration = require('./plan_illustration.png');
const infoIcon = require('./info_icon.svg');

const subscribedMessages = [
    MessageEnum.RestAPIResponceMessage,
    MessageEnum.SessionResponseMessage,
];

const completeProgress = {
    "partner_partner_bank_details": {
        title: "Add bank details",
        image: bank_icon,
        imageAlt: "Bank details",
        link: "/payout-settings"
    },
    "partner_members": {
        title: "Add members",
        image: members_icon,
        imageAlt: "member",
        link: "/teams"
    },
    "partner_leads": {
        title: "Add your first referral",
        image: lead_icon,
        imageAlt: "lead",
        link: "/register-referral"
    },
    "partner_profile": {
        title: "Complete your profile",
        image: profile_icon,
        imageAlt: "Profile",
        link: "/user-profile"
    }
} as {
    [key: string]: {
        title: string;
        image: string;
        imageAlt: string;
        link: string;
    }
}

const progressGrowth = {
    0: 0,
    1: 25,
    2: 50,
    3: 75,
    4: 100
} as any

const CircularProgress: React.FC<CircularProgressProps> = ({ size, progress, progressCompleted, progressUpto }) => {
    const strokeWidth = 2;
    const radius = size / 2 - strokeWidth * 2;
    const circumference = radius * 2 * Math.PI;
    const strokeDashoffset = circumference - progress / 100 * circumference;

    return (
        <svg height={size} width={size} viewBox={`0 0 ${size} ${size}`}>
            <circle
                stroke="#B388FF"
                fill="transparent"
                strokeWidth={strokeWidth}
                strokeDasharray={circumference + ' ' + circumference}
                style={{ transition: 'stroke-dashoffset 0.35s', transform: 'rotate(-90deg)', transformOrigin: 'center' }}
                r={radius}
                cx={size / 2}
                cy={size / 2}
            />
            <circle
                stroke="#fff"
                fill="transparent"
                strokeWidth={strokeWidth}
                strokeDasharray={circumference + ' ' + circumference}
                style={{ transition: 'stroke-dashoffset 0.35s', strokeDashoffset, transform: 'rotate(-90deg)', transformOrigin: 'center' }}
                strokeLinecap="round"
                r={radius}
                cx={size / 2}
                cy={size / 2}
            />
            <text
                x="50%"
                y="50%"
                dy=".3em"
                textAnchor="middle"
                style={{ fontSize: '10px', fontWeight: 'bold', fill: '#fff' }} // Adjust font size as needed
            >
                {`${progressCompleted}/${progressUpto}`}
            </text>
        </svg>
    );
}

const SideNav = ({ history }: any) => {

    const [navItem, setNavItem] = useState("/dashboard")
    const [expanded, setExpanded] = useState<string|null>(null);
    const [openDialog, setOpen] = useState(false);
    const [openVideoDialog, setVideoOpen] = useState(false);
    const [isIframeLoading, setIsIframeLoading] = useState(true);
    const getStartedStatusId = useRef("")
    const supportTicketId = useRef("")
    const [startedProgress, setStartedProgress] = useState<{ [key: string]: boolean }>(JSON.parse(localStorage.getItem('onBoarding') || "{}"))
    const [openConfirmationDialog, setConfirmationOpen] = useState(false);
    const [onDropDownBlur, setOnDropDownBlur] = useState(false)
    const [formSubmit, setFormSubmit] = useState(false)


    const supportSchema = Yup.object().shape({
        title: Yup.string().required("required"),
        email: Yup.string().email("Please provide a valid email address.").required("Please provide a valid email address."),
        description: Yup.string().required("required"),
        help_required: Yup.string().required("required"),
    });

    useEffect(() => {
        setIsIframeLoading(true)
    }, [openDialog]);

    const handleDialogOpen = () => {
        setOpen(true);
    };

    const handleDialogClose = () => {
        setOpen(false);
        setHelpOption("")
        setOnDropDownBlur(false)
    };

    const handleVideoDialogOpen = () => {
        setVideoOpen(true);
    };

    const handleVideoDialogClose = () => {
        setVideoOpen(false);
    };

    const handleConfirmationModalOpen = () => {
        setConfirmationOpen(true);
    };

    const handleConfirmationModalClose = () => {
        setConfirmationOpen(false);
    };

    const sideNavOptions = [
        { label: 'Dashboard', value: 'dashboard', logo: dashboard, active_logo: dashboard_active, navigation: "/dashboard" },
        { label: 'Referrals', value: 'deals', logo: deals, active_logo: deals_active, navigation: "/referrals" },
        { label: 'Asset library', value: 'assets', logo: assets, active_logo: assets_active, navigation: "/asset-library" },
        { label: 'Training courses', value: 'training', logo: training, active_logo: training_active, navigation: "/training" },
        { label: 'Commission report', value: 'commision', logo: commision, active_logo: commision_active, navigation: "/commissions" },
        { label: 'Companies', value: 'companies', logo: lead_companies, active_logo: lead_companies_active, navigation: "/companies" },
    ]

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        setNavItem(window.location.pathname)
    }, [window.location.pathname])

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        //@ts-ignore
        setExpanded(isExpanded ? panel : null);
    };

    useEffect(() => {
        // Check if any of the sub-items of "My Company" is active
        // const navItem=window.location.pathname
        console.log('navItem: ', navItem);
        if (navItem === '/teams' || navItem === '/contract-details' || navItem === '/payout-settings') {
            setExpanded('panel1'); // Expand the accordion when any of the sub-items is active
        } 
        else if(navItem==='/training/overview' || navItem==='/training/achivements'){
            setExpanded('panel2');
        }
        else {
            setExpanded(null); // Collapse the accordion otherwise
        }
    }, [navItem]);

    const {
        sendBlockMessage,
        sendNetworkRequest,
        setReceiveCallback,
        subscribe,
        debugLog,
        unsubscribeFromMessage,
    } = useRunEngine();
    const { extractNetworkResponse, hideKeyboard, isPlatformWeb } = useBlockHelpers();

    useEffect(() => {
        setReceiveCallback(receive);
        // subscribedMessages.forEach((message) => subscribe(message));
        getStartedStatus();
        // return () => {
        //     subscribedMessages.forEach((message) => unsubscribeFromMessage(message));
        // };
    }, [localStorage.getItem("getStarted")]);

    useEffect(() => {
        sideNavOptions.forEach(item => {
            const imgActive = new Image();
            imgActive.src = item.active_logo;

            const imgInactive = new Image();
            imgInactive.src = item.logo;
        });
    }, [])

    const getStartedStatus = () => {
        const headers = {
            "Content-Type": "application/json",
            token: localStorage.getItem('authToken'),
        };

        sendNetworkRequest(
            getStartedStatusId,
            "GET",
            "/bx_block_dashboard/dashboards/onboarding_data",
            headers
        );
    };

    const receive = (from: string, message: Message) => {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const { apiRequestCallId, responseJson } =
                extractNetworkResponse(message);
            if (apiRequestCallId === getStartedStatusId.current) {
                if (!responseJson.errors) {
                    const login_count = Number(localStorage.getItem('login_count') || 0)
                    if (login_count <= 10 && getToken('partner_role') !== 'member') {
                        delete responseJson['show_menu']
                        if (Object.keys(responseJson).find(key => !responseJson[key])) {
                            localStorage.setItem('onBoarding', JSON.stringify(responseJson))
                        } else {
                            localStorage.removeItem('onBoarding')
                        }
                        setStartedProgress(responseJson)
                    }
                }
                localStorage.removeItem("getStarted")
            }
            if (supportTicketId.current === apiRequestCallId) {
                if (responseJson.success) {
                    setConfirmationOpen(true)
                }
                handleDialogClose()
                setFormSubmit(false)
            }
        }
    };

    const [helpOption, setHelpOption] = useState<any>("");

    const helpOptions = [
        { value: "Platform support", label: "Platform support" },
        { value: "Business support", label: "Business support" }
    ];

    const handleSelectChange = (selectedOption: any, formik: FormikProps<any>) => {
        setHelpOption(selectedOption);
        formik.setFieldValue("help_required", selectedOption.value)
    };

    const { Option } = components;
    const selectOption = (props: any) => (
        <Option {...props}>
            {props.data.label}
            {helpOption && helpOption.value === props.data.value && (
                <img src={checkIcon} style={{ marginLeft: '10px' }} />
            )}
        </Option>
    );

    const postSupportTicket = async (value: {
        title: string;
        email: string;
        description: string;
        help_required: string;
    }) => {
        setFormSubmit(true)
        const headers = {
            "Content-Type": "application/json",
            token: await getStorageData('authToken'),
        };

        const body = {
            "data": {
                "name": value.title,
                "email": value.email,
                "description": value.description,
                "help_required": value.help_required
            }
        }

        sendNetworkRequest(
            supportTicketId,
            "POST",
            "/bx_block_ticketsystem/tickets",
            headers,
            body
        );
    };

    return (
        <div className="nav-container">
            <div>
                <div className="logo-wrapper">
                    <img src={siteLogo} className="main-logo" alt="Builder.ai" />
                </div>
                {/* <Button title="Home" onPress={() => history.push("/")} /> */}
                <ul className="menu-list">
                    {
                        sideNavOptions.map((item, index) => {
                            return (
                            //     index===2?
                            //     <>
                            //     <li onClick={() => {
                            //         setNavItem(item.navigation)
                            //         history.push(item.navigation)
                            //         localStorage.removeItem('company_name')
                            //         localStorage.removeItem('company_id')
                            //     }} className={item.navigation === navItem ? "nav-item nav-item-active" : "nav-item"} key={index}>
                            //         <img src={item.navigation === navItem ? item.active_logo : item.logo || item.logo} alt="item.label" /><label className="nav-label">{item.label}</label>
                            //     </li>
                            //     <li style={{margin:"0 0 16px"}}>
                            //     <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className={expanded === 'panel2' ? 'nav-accordion active-accordion-item' : 'nav-accordion'}>
                            //         <AccordionSummary
                            //             expandIcon={expanded === 'panel2' ? <img className="arrow-img" src={down_arrow_active} /> : <img className="arrow-img" src={down_arrow} />}
                            //             aria-controls="panel2bh-content"
                            //             id="panel2bh-header"
                            //             className="nav-accordion-title nav-item"
                            //         >
                            //             <img src={expanded === 'panel2' ? training_active : training} alt='' />Training courses
                            //         </AccordionSummary>
                            //         <AccordionDetails className="accordion-nav-list">
                            //             <ul>
                            //                 <li onClick={() => {
                            //                     setNavItem("/training/overview")
                            //                     history.push("/training/overview")
                            //                 }}
                            //                     className={navItem === '/training/overview' ? "active-item" : "not-active-item"}
                            //                 >
                            //                     Overview
                            //                 </li>
                            //                 <li onClick={() => {
                            //                     setNavItem("/training/achivements")
                            //                     history.push("/training/achivements")
                            //                 }}
                            //                     className={navItem === '/training/achivements' ? "active-item" : "not-active-item"}
                            //                 >
                            //                     Achivements
                            //                 </li>
        
                            //             </ul>
                            //         </AccordionDetails>
                            //     </Accordion>
                            // </li>
                            // </>:
                                <li onClick={() => {
                                    setNavItem(item.navigation)
                                    history.push(item.navigation)
                                    localStorage.removeItem('company_name')
                                    localStorage.removeItem('company_id')
                                }} className={item.navigation === navItem ? "nav-item nav-item-active" : "nav-item"} key={index}>
                                    <img src={item.navigation === navItem ? item.active_logo : item.logo || item.logo} alt="item.label" /><label className="nav-label">{item.label}</label>
                                </li>
                            )
                        })
                    }


                    {
                        getToken('partner_role') !== 'member' &&
                        <li>
                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className={expanded === 'panel1' ? 'nav-accordion active-accordion-item' : 'nav-accordion'}>
                                <AccordionSummary
                                    expandIcon={expanded === 'panel1' ? <img className="arrow-img" src={down_arrow_active} /> : <img className="arrow-img" src={down_arrow} />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                    className="nav-accordion-title nav-item"
                                >
                                    <img src={expanded === 'panel1' ? companies_active : companies} alt='' />Account
                                </AccordionSummary>
                                <AccordionDetails className="accordion-nav-list">
                                    <ul>
                                        <li onClick={() => {
                                            setNavItem("/teams")
                                            history.push("/teams")
                                            localStorage.removeItem('company_name')
                                            localStorage.removeItem('company_id')
                                        }}
                                            className={navItem === '/teams' ? "active-item" : "not-active-item"}
                                        >
                                            Teams
                                        </li>
                                        <li onClick={() => {
                                            setNavItem("/contract-details")
                                            history.push("/contract-details")
                                        }}
                                            className={navItem === '/contract-details' ? "active-item" : "not-active-item"}
                                        >
                                            Contract details
                                        </li>
                                        <li onClick={() => {
                                            setNavItem("/payout-settings")
                                            history.push("/payout-settings")
                                        }}
                                            className={navItem === '/payout-settings' ? "active-item" : "not-active-item"}
                                        >
                                            Payout settings
                                        </li>

                                    </ul>
                                </AccordionDetails>
                            </Accordion>
                        </li>
                    }
                </ul>
            </div>
            <div>
                {
                    Object.keys(startedProgress).length && Object.keys(startedProgress).find(key => !startedProgress[key]) ?
                        <button className="get-started-wrap" aria-describedby={id} color="primary" onClick={handleClick}>
                            <CircularProgress
                                size={38}
                                progress={progressGrowth[Object.keys(startedProgress).filter((x: string) => startedProgress[x]).length]}
                                progressUpto={Object.keys(startedProgress).length}
                                progressCompleted={Object.keys(startedProgress).filter((x: string) => startedProgress[x]).length}
                            />
                            <span className="get-started-text">Get started</span>
                            {
                                anchorEl ?
                                    <img
                                        className="get-started-icon"
                                        src={whiteCloseIcon}
                                        alt="close"
                                        onClick={handleDialogClose}
                                        style={{ top: "18px" }}
                                    />
                                    :
                                    <img className="get-started-icon" src={white_arrow} />
                            }
                        </button> : <></>
                }
                <div className="watch-demo-wrap">
                    <div className="watch-demo-link" onClick={handleVideoDialogOpen}>
                        <div className="left-block">
                            <i className="play-icon">
                                <img src={videoIcon} alt="videoIcon" />
                            </i>
                            <span>Watch a demo</span>
                        </div>
                        <i className="right-arrow">
                            <img src={rightArrow} alt="rightArrow" />
                        </i>
                    </div>
                    <Modal
                        open={openVideoDialog}
                        onClose={handleVideoDialogClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className="custom-modal instruction-video-modal"
                        BackdropProps={{
                            className: "custom-modal-backdrop"
                        }}
                    >
                        <div className="modal-body">
                            <div className="modal-inner-wrap">
                                <div className="modal-heading">
                                    <Typography variant="h4">
                                        Partner Hub introduction video
                                    </Typography>
                                    <IconButton disableRipple className="close-btn" onClick={handleVideoDialogClose}>
                                        <img src={boldCloseIcon} alt="close" />
                                    </IconButton>
                                </div>
                                <div className="instructionVideoModal">
                                    <div className="video-wrap">
                                        <video autoPlay controls controlsList="nodownload">
                                            <source src={demoVideo} type="video/mp4" />
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
                <div className="help-link-wrap">
                    <p className="help-link"
                        // onClick={() => {
                        //     setNavItem("/TicketSystem")
                        //     history.push("/TicketSystem")
                        // }}
                        onClick={handleDialogOpen}
                    >
                        <img src={help_support} alt="Help and support" className="help-img" />Help and support
                    </p>

                    <HelpAndSupport openModal={openDialog} closeModal={() => { setOpen(false) }} />

                </div>

                <Popover
                    className="get-started-popover"
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div className="get-started-steps-wrap">
                        <Box display={'flex'} justifyContent={"space-between"} alignItems={"center"}>
                            <div className="get-started-title">Welcome to Builder partners!</div>
                            <IconButton disableRipple className="close-btn" onClick={handleClose}>
                                <img src={boldCloseIcon} alt="close" style={{ width: "15px" }} />
                            </IconButton>
                        </Box>
                        <div className="get-started-subtitle">Get up and running with these quick few steps ahead.</div>
                        <div className="get-started-steps">
                            {
                                Object.keys(startedProgress).filter(x => !startedProgress[x]).map((key: string) => {
                                    if (completeProgress[key]) {
                                        const { title, image, imageAlt, link } = completeProgress[key]
                                        return (
                                            <div className="step" onClick={() => {
                                                setNavItem(link)
                                                history.push(link)
                                            }}>
                                                <div className="step-number"><img src={image} alt={imageAlt} /></div>
                                                <div className="step-text">{title}</div>
                                                <div className="step-arrow-img"><img src={down_arrow} /></div>
                                            </div>
                                        )
                                    }
                                })

                            }

                            {
                                Object.keys(startedProgress).filter(x => startedProgress[x]).map((key: string) => {
                                    if (completeProgress[key]) {
                                        const { title, image, imageAlt, link } = completeProgress[key]
                                        return (
                                            <div className="step" onClick={() => {
                                                setNavItem(link)
                                                history.push(link)
                                            }}>
                                                <div className="step-number"><img src={image} alt={imageAlt} /></div>
                                                <div className="step-text">{title}</div>
                                                <div className="step-checked"><img src={checked_circle} /></div>
                                            </div>
                                        )
                                    }
                                })
                            }

                            {/* <div className="step" onClick={() => {
                                setNavItem("/teams")
                                history.push("/teams")
                            }}>
                                <div className="step-number"><img src={members_icon} alt="Members" /></div>
                                <div className="step-text">Add members</div>
                                <div className="step-arrow-img"><img src={down_arrow} /></div>
                            </div>

                            <div className="step" onClick={() => {
                                setNavItem("/register-referral")
                                history.push("/register-referral")
                            }}>
                                <div className="step-number"><img src={lead_icon} alt="First Lead" /></div>
                                <div className="step-text">Add your first lead</div>
                                <div className="step-arrow-img"><img src={down_arrow} /></div>
                            </div>

                            <div
                                className="step"
                                onClick={() => {
                                    setNavItem("/user-profile")
                                    history.push("/user-profile")
                                }}>
                                <div className="step-number"><img src={profile_icon} alt="Profile" /></div>
                                <div className="step-text">Complete your profile</div>
                                <div className="step-checked"><img src={checked_circle} /></div>
                            </div> */}
                        </div>
                    </div>
                </Popover>

            </div>
        </div>
    );
};

const styles = StyleSheet.create({
    main: {
        flexDirection: "row",
        justifyContent: "space-evenly",
        backgroundColor: "#cccccc"
    }
});

export default withRouter(SideNav);

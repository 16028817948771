import React from 'react';
import { Line } from "react-chartjs-2";
import moment from 'moment';

export default function WineRatioLineGraph(props: any) {
    const chartData = {
        labels: props.data.labelDate,
        datasets: [
            {
                label: 'Closed Referrals',
                data: props.data.over_all_closed_conversion_graph.map((item: any) => item.count),
                borderColor: '#304FFE',
                backgroundColor: 'rgba(48, 79, 254, 0.2)',
                fill: true,
                borderWidth: 2,
                tension: 0.4,
                pointRadius: 4,
                pointBackgroundColor: '#304FFE',
                pointBorderColor: '#FFFFFF',
            },
            {
                label: 'Total Referrals',
                data: props.data.leads_and_opportunities_by_graph.map((item: any) => item.count),
                borderColor: '#6200EA',
                backgroundColor: 'rgba(98, 0, 234, 0.2)',
                fill: true,
                borderWidth: 2,
                tension: 0.4,
                pointRadius: 4,
                pointBackgroundColor: '#6200EA',
                pointBorderColor: '#FFFFFF',
            },
        ],
    };

    return (
        <Line
            data={chartData}
            options={{
                maintainAspectRatio: false,
                plugins: {
                    tooltip: {
                        enabled: true,
                        displayColors: true,
                        backgroundColor: "#3C3E49",
                        titleColor: "#fff",
                        bodyColor: "#fff",
                        padding: 16,
                        titleFont: {
                            family: 'Rubik',
                            weight: 'normal',
                            size: 12,
                        },
                        bodyFont: {
                            family: 'Rubik',
                            size: 14,
                        },
                        callbacks: {
                            title: (tooltipItems) => {
                                return moment(tooltipItems[0].label).format('ddd DD MMMM');
                            },
                            label: (tooltipItem) => {
                                const datasetLabel = tooltipItem.dataset.label || "";
                                return `${datasetLabel}: ${tooltipItem.raw}`;
                            },
                        },
                    },
                    legend: {
                        display: true,
                        position: 'bottom',
                        labels: {
                            color: "#3C3E49",
                            font: {
                                family: 'Rubik',
                                size: 12,
                            },
                            usePointStyle: true,
                        },
                    },
                },
                elements: {
                    point: {
                        radius: 4,
                        backgroundColor: "#fff",
                        borderWidth: 2,
                        borderColor: "#000",
                        hoverRadius: 6,
                    },
                },
                scales: {
                    y: {
                        beginAtZero: false,
                        ticks: {
                            stepSize: 1,
                            color: "#83889E",
                            font: {
                                family: "Rubik",
                                size: 12,
                            },
                        },
                        grid: {
                            color: "#ECEDF0",
                        },
                    },
                    x: {
                        ticks: {
                            color: "#83889E",
                            font: {
                                family: "Rubik",
                                size: 12,
                            },
                            callback: function (val: any) {
                                const label = this.getLabelForValue(val);
                                return [
                                    moment(label).format("DD"),
                                    moment(label).format("MMM"),
                                ];
                            },
                        },
                        grid: {
                            display: false,
                        },
                    },
                },
                aspectRatio: 2,
            }}
        />
    );
}
